import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication1/Login1')));
const AuthLoginEntrar = Loadable(lazy(() => import('views/pages/authentication/authentication1/Login1Entrar')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const AuthAdmitionRegister = Loadable(lazy(() => import('views/admissionregister/AdmitionRegisterForm')));
const AuthAdmitionRegisterEntrar = Loadable(lazy(() => import('views/admissionregister/AdmissionRegistrationFormEntrar')));
const AuthDistanceAdmitionRegister = Loadable(lazy(() => import('views/distanceeducationadmission/DistanceEducationForm')));

const OTPPage = Loadable(lazy(() => import('views/pages/authentication/authentication1/OTP')));
const RegistrationProfile = Loadable(lazy(() => import('views/profile/RegistrationProfile')));
const AdmissionProfile = Loadable(lazy(() => import('views/profile/AdmissionProfile')));
const RegistrationReceipt = Loadable(lazy(() => import('views/profile/RegistrationReceipt')));
const RegistrationFeeReceipt = Loadable(lazy(() => import('views/profile/AdmissionFeeReceipt')));
const StudentAcedemicAdmission = Loadable(lazy(() => import('views/profile/StudentAcedemicAdmisson')));
const ChangeUserPassword = Loadable(lazy(() => import('views/pages/authentication/authentication1/ResetPassword1')));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/admission/:id',
            element: <AuthAdmitionRegister />
        },
        {
            path: '/distance/admission/:id',
            element: <AuthDistanceAdmitionRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/otp/page',
            element: <OTPPage />
        },
        {
            path: '/registration/profile/:id',
            element: <RegistrationProfile />
        },
        {
            path: '/admission/profile/:id',
            element: <AdmissionProfile />
        },
        {
            path: '/registration/receipt/:userId/:FeeCodeId',
            element: <RegistrationReceipt />
        },
        {
            path: '/admission/fee/receipt/:receiptId',
            element: <RegistrationFeeReceipt />
        },
        {
            path: '/student/acedemicadmission/:receiptId',
            element: <StudentAcedemicAdmission />
        },
        {
            path: '/ustmentrar/password/change/:tokens',
            element: <ChangeUserPassword />
        }
    ]
};

export default LoginRoutes;
